/**
 * External Dependencies
 */

import Swiper from 'swiper/bundle';
import InfiniteScroll from 'infinite-scroll';
import lozad from 'lozad';
import * as cartApi from './checkout-cart-api.js';
import * as utils from './utils.js';
import * as navigation from './navigation.js';
import * as checkout from './checkout.js';

const app = (function() {
  'use strict';

  // global
  let body = document.querySelector('body');

  // utility
  function addActiveClass(el) {
    el.classList.add('active');
  }

  function removeActiveClass(el) {
    el.classList.remove('active');
  }

  // blocks
  function handleLazyLoad() {
    const observer = lozad('.lazy');
    observer.observe();
  }

  function handleResourcesTabs() {
    let resourcesBlock = document.querySelector('.resources-block');

    if (resourcesBlock != null) {
      resourcesBlock.addEventListener('click', function(e) {
        if (e.target.classList.contains('resources-block__tab')) {
          let tab = e.target;

          // toggle yellow background
          tab.classList.toggle('bg-yellow');

          // toggle tabs content
          tab.nextElementSibling.classList.toggle('hidden');

          // rotate chevron
          tab.querySelector('svg').classList.toggle('rotate-90');
        }
      });
    }
  }

  function handleSliders() {
    // gallery Gutenberg block
    var gallerySlider = new Swiper('.gallery .swiper-container', {
      slidesPerView: 1.3,
      spaceBetween: 16,
      breakpoints: {
        // when window width is >= 320px
        768: {
          slidesPerView: 1.7,
          spaceBetween: 32,
        },
        1152: {
          slidesPerView: 2,
        },
      },
    });
    var artistSlider = new Swiper('.artist-slider .swiper-container', {
      slidesPerView: 1,
    });
  }

  function handleMobileMenu() {
    const burger = document.querySelector('.burger');
    const headerMenu = document.querySelector('.header__menu');
    const subMenus = document.querySelectorAll('.sub-menu');
    const menuItemHasChildren = document.querySelectorAll(
      '.menu-item-has-children > a'
    );

    // Sanity check
    if (!burger) {
      return;
    }

    // Close the burger menu on logout
    document
      .querySelector('body')
      .addEventListener('elp:account:logout', event => {
        burger.classList.remove('burger--active');
        headerMenu.classList.remove('header__menu--active');
        body.classList.remove('disable-scrolling');
      });

    burger.addEventListener('click', function() {
      burger.classList.toggle('burger--active');
      headerMenu.classList.toggle('header__menu--active');
      body.classList.toggle('disable-scrolling');
    });

    // add chevron to each link that is a direct child of a menu item with children
    menuItemHasChildren.forEach(function(e) {
      let chevron = document.createElement('span');

      chevron.classList.add(
        'inline-block',
        'ml-2',
        'md:h-8',
        'md:w-8',
        'md:w-8',
        'lg:hidden'
      );

      chevron.innerHTML =
        '<svg width="13" height="20" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 2L10 10L2 18" stroke="#141414" stroke-width="3"/></svg>';

      e.appendChild(chevron);
    });

    // append back arrow to each sub-menu
    subMenus.forEach(function(e) {
      let backArrow = document.createElement('div');

      backArrow.classList.add('sub-menu__back');

      backArrow.innerHTML =
        "<svg width='19' height='35' fill='none' xmlns='http://www.w3.org/2000svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M17.322 0L0 17.322l.002.002-.002.002 17.322 17.322.707-.707L1.412 17.324 18.03.707 17.322 0z' fill='#141414'/></svg>";

      e.appendChild(backArrow);
    });

    // toggle sub menus when menuItemhasChildren is clicked
    if (window.innerWidth < 1024) {
      headerMenu.addEventListener('click', function(e) {
        function toggleBurger() {
          burger.classList.toggle('opacity-0');
        }

        // check for menu item with children
        if (e.target.parentNode.classList.contains('menu-item-has-children')) {
          e.preventDefault();
          e.target.parentNode
            .querySelector('.sub-menu')
            .classList.toggle('active');
          toggleBurger();
        }

        if (e.target.parentNode.classList.contains('sub-menu__back')) {
          e.target.parentNode.parentNode.classList.toggle('active');
          toggleBurger();
        }
      });
    }
  }

  function handlePageButton() {
    let button = document.querySelector('.page__button');

    if (button !== null) {
      let pageContent = document.querySelector('.page__content'),
        pageContentBottomPosition =
          pageContent.offsetHeight + pageContent.offsetTop;

      window.addEventListener('scroll', function() {
        let scrollTop =
            window.pageYOffset !== undefined
              ? window.pageYOffset
              : (
                  document.documentElement ||
                  document.body.parentNode ||
                  ppagedocument.body
                ).scrollTop,
          scrollBottom = window.innerHeight + scrollTop;

        if (scrollTop > 50 && scrollBottom < pageContentBottomPosition) {
          button.classList.add('page__button--active');
        } else {
          button.classList.remove('page__button--active');
        }
      });
    }
  }

  function handlePaymentMethods() {
    let wcPaymentMethods = document.querySelectorAll(
      'ul.wc_payment_methods li input'
    );

    if (wcPaymentMethods !== null) {
      wcPaymentMethods.addEventListener('click', function() {
        let scrollTop =
            window.pageYOffset !== undefined
              ? window.pageYOffset
              : (
                  document.documentElement ||
                  document.body.parentNode ||
                  ppagedocument.body
                ).scrollTop,
          scrollBottom = window.innerHeight + scrollTop;

        button.classList.add('page__button--active');
      });
    }
  }

  function handleInfiniteScroll() {
    let blogButton = document.querySelector('.blog__more');

    if (blogButton !== null) {
      let infScroll = new InfiniteScroll('.blog__three-column', {
        path: '.blog__more a',
        append: '.blog__post',
        history: false,
        loadOnScroll: false,
        button: '.blog__more a',
      });

      if (blogButton !== null) {
        blogButton.addEventListener('click', function(e) {
          e.preventDefault();
          this.classList.add('hidden');
        });
      }
    }
  }

  function handleCookiesNotice() {
    let cookies = document.getElementById('cookies');

    // stop execution if cookies notice has already been closed
    if (JSON.parse(localStorage.getItem('gate')) === false) return;

    let open = false;

    removeActiveClass(cookies);

    // store cookies close response in local storage to avoid it reappearing
    cookies.addEventListener('click', function(e) {
      if (e.target.classList.contains('cookies__cross')) {
        // add active class to cookies to apply a fade out animation
        addActiveClass(cookies);

        localStorage.setItem('gate', JSON.stringify(open));
      }
    });
  }

  function handleCoursesFilter() {
    let coursesBlock = document.getElementsByClassName('filters')[0],
      coursesButtons = document.getElementsByClassName('filters__btn');

    if (coursesBlock != undefined) {
      coursesBlock.addEventListener('click', function(e) {
        // only assign e.target if it's a filter button
        if (e.target.classList.contains('filters__btn')) {
          let coursesBlockButton = e.target,
            selectedCat = coursesBlockButton.dataset.cat,
            cardsContainer = document.querySelector('.filters__cards'),
            cards = document.getElementsByClassName('filters__card');

          // cycle through filter buttons removing active class
          for (let i = 0; i < coursesButtons.length; i++) {
            let btn = coursesButtons[i];
            btn.classList.remove('filters__btn--active');
          }

          // add active class to selected button
          coursesBlockButton.classList.add('filters__btn--active');

          // hide unrelated course cards
          for (let i = 0; i < cards.length; i++) {
            let card = cards[i];

            card.classList.remove('hidden');

            if (selectedCat === '*') {
              card.classList.remove('hidden');
            } else if (!card.classList.contains(selectedCat)) {
              card.classList.add('hidden');
            }
          }
        }
      });
    }
  }

  function handleShopLoadMore() {
    let btn = document.getElementsByClassName(
      'post-type-archive-product__btn'
    )[0];

    if (btn != undefined) {
      btn.addEventListener('click', function(e) {
        e.preventDefault();

        let cards = document.querySelectorAll('.filters__card');

        for (let i = 0; i < cards.length; i++) {
          const element = cards[i];

          if (i > 8) {
            element.classList.remove('hidden');
          }
        }

        btn.classList.add('hidden');
      });
    }
  }

  function handleArtistImage() {
    let artistTitle = document.getElementsByClassName('artist-block__name');
    let artistImages = document.getElementsByClassName('artist-block__image');
    let artistImagesInner = document.getElementsByClassName(
      'artist-block__image-inner'
    );

    if (artistTitle != undefined) {
      for (let i = 0; i < artistTitle.length; i++) {
        artistTitle[i].addEventListener('click', function(e) {
          e.preventDefault();

          if (window.innerWidth > 640 && window.innerWidth < 1200) {
            for (let j = 0; j < artistImages.length; j++) {
              artistImages[j].style.display = 'none';
            }

            let artistId = e.srcElement.id;
            let imageId = artistId + '_image';

            let image = document.getElementById(imageId);

            image.style.display = 'block';
          }
          // Here we check the window width and toggle the image appearing
        });
      }

      window.addEventListener('resize', function(e) {
        e.preventDefault();

        if (window.innerWidth < 640 && window.innerWidth > 1200) {
          for (let j = 0; j < artistImagesInner.length; j++) {
            artistImagesInner[j].style.display = 'block';
          }
        }
      });
    }
  }

  function handleAddToCartJS() {
    jQuery('[href^="?add-to-cart"]').each(function() {
      jQuery(this).click(function(e) {
        e.preventDefault();
        console.log('clicked');
        let itemsToAdd = [];
        itemsToAdd.push({
          id: jQuery(this)
            .attr('href')
            .split('=')[1],
        });
        cartApi.addItemsToCart(itemsToAdd, () => {
          document.querySelector('body').classList.add('checkout-active');
          utils.dispatchGlobal('elp:cart:goto-page', { page: 0 });
        });
      });
    });
  }

  return {
    init: function() {
      navigation.init();
      checkout.init();
      handlePageButton();
      handleInfiniteScroll();
      handleSliders();
      handleMobileMenu();
      handleLazyLoad();
      handleCookiesNotice();
      handleCoursesFilter();
      handleShopLoadMore();
      handleResourcesTabs();
      handleAddToCartJS();
      //handlePaymentMethods();
      handleArtistImage();
    },
  };
})();

/*document.getElementById("media").addEventListener("change", function(event){
  var files = event.target.files; //FileList object
  var output = document.getElementById("media_result");
  for(var i = 0; i< files.length; i++)
  {
      var file = files[i];
      if(!file.type.match('image'))
        continue;
      var picReader = new FileReader();
      picReader.addEventListener("load",function(event){
          var picFile = event.target;
          var div = document.createElement("div");
          div.className += "thumb_box p-2 sm:p-2 md:p-4 w-1/2 sm:w-1/2 md:w-1/4";
          div.innerHTML = "<div class='thumb_box_wrapper bg-gray-100 p-2'><div class='thumb_box   relative pb-100-percent'><img class='thumbnail absolute left-0 top-0 w-full h-full object-contain' src='" + picFile.result + "'" +
                  "title='" + picFile.name + "'/></div></div>";
          output.insertBefore(div,null);
      });
      //Read the image
      picReader.readAsDataURL(file);
  }
});*/

$('.join_click').click(function() {
  $('.join_us_dropdown').toggleClass('open');
  $('.join_click').toggleClass('open');
});

app.init();
